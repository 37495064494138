<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Bảng thống kê hoàn thành nhiệm vụ</h6>
              </template>
              <div class="filter-transaction">
                <b-form @submit.prevent="search()" >
                <div class="row mb-6">
                  <div class="col-lg-3 mb-lg-0 mb-6">
                    <label>Số điện thoại:</label>
                    <input
                        type="text"
                        class="form-control datatable-input"
                        placeholder="Nhập số điện thoại..."
                        v-model="paramFilter.phone"
                    />
                  </div>
                  <div class="col-lg-3 mb-lg-0 mb-6">
                    <label>Tên thử thách:</label>
                    <input
                        type="text"
                        class="form-control datatable-input"
                        v-model="paramFilter.challenge_name"
                        placeholder="Nhập tên thử thách..."
                    />
                  </div>

                  <div class="col-lg-3 mb-lg-0 mb-6">
                    <label>Nhiệm vụ:</label>
                    <select
                        class="form-control datatable-input"
                        data-col-index="7"
                        v-model="paramFilter.mission_id"
                    >
                      <option value="">Tất cả</option>
                      <option v-for="(item, index) in missions" :key="index" :value="item.id">{{ item.title }}</option>
                    </select>
                  </div>


                </div>
                <div class="row mb-6">
                  <div class="col-lg-4 mb-lg-0 mb-6">
                    <label>Thời gian hoàn thành:</label>
                    <div class="input-daterange input-group" id="kt_datepicker">
                      <input
                          type="date"
                          v-model="paramFilter.date_start_finish"
                          class="form-control datatable-input"
                          name="start"
                          placeholder="Từ"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="la la-ellipsis-h"></i>
                        </span>
                      </div>
                      <input
                          type="date"
                          v-model="paramFilter.date_end_finish"
                          class="form-control datatable-input"
                          name="end"
                          placeholder="Đến"
                      />
                    </div>
                  </div>
                  <div class="col-lg-2 mb-lg-0 mb-6">
                    <label>.</label><br />
                    <button
                        class="btn btn-primary btn-primary--icon"
                    >
                      <span>
                        <i class="la la-search"></i>
                        <span>Tìm kiếm</span>
                      </span>
                    </button>
                  </div>
                </div>
                </b-form>
              </div>

              <b-table
                  table-class="datatable-table"
                  hover
                  tbody-tr-class="datatable-row"
                  thead-tr-class="datatable-row"
                  thead-class="datatable-head"
                  details-td-class="datatable-cell"
                  :items="items"
                  :fields="fieldsLog"
              >

                <template #cell(user_info)="data">
                  <span> SĐT : {{ data.item.phone }} </span> <br />
                  <span> User ID: {{ data.item.uuid }} </span><br />
                  <span v-if="data.item.name">
                    họ tên: {{ data.item.name }}
                  </span>
                </template>

                <template #cell(mission_result)="data">
                  <span v-if="data.item.mission_code == 'PAYMENT'"> Đơn hàng {{ data.item.value }} - {{ numberFormat(data.item.amount) }} </span>
                  <span v-if="data.item.mission_code == 'ATTENDANCE'"> Ngày điểm danh {{ data.item.value }} </span>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :total-rows="paginate.total"
                    :per-page="paginate.per_page"
                    use-router
                    first-number
                    last-number
                    align="right"
                ></b-pagination>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const WalletCampaignRepository = RepositoryFactory.get("Campaign");

export default {
  mixins: [Common],
  data() {
    return {
      paramFilter: {
        phone: "",
        challenge_name: "",
        mission_id: "",
        date_start_finish: '',
        date_end_finish: '',
        limit: 20,
      },
      fieldsLog: [
        // {
        //   key: "id",
        //   label: "ID",
        // },
        {
          key: "user_info",
          label: "Users",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "mission_title",
          label: "Tiêu đề nhiệm vụ",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "mission_desc",
          label: "Mô tả nhiệm vụ",
          tdClass: "datatable-cell set-width",
          thClass: "datatable-cell",
        },
        {
          key: "mission_result",
          label: "Kết quả nhiệm vụ",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "challenge_title",
          label: "Thử thách",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "created_at",
          label: "Thời gian hoàn thành",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      missions: [],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },
  created() {
    this.loadMissions();
    this.loadData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Thống kê" },
      { title: "Thống kê nhiệm vụ", route: "/campaign/report/missions" },
    ]);
  },
  watch: {
    currentPage() {
      this.loadData();
    },
  },
  methods: {
    async loadData() {
      try {
        this.$bus.$emit("show-loading", true);
        this.paramFilter.page = this.currentPage;
        let response = await WalletCampaignRepository.reportMissions(this.paramFilter);
        this.$bus.$emit("show-loading", false);
        let body = response.data.data;
        this.items = body.items;
        this.paginate = body.meta;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async search () {
      this.currentPage = 1;
      this.paginate = {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      }
      this.$router.push({path: this.$route.fullPath, query: {page: this.currentPage} });
      this.loadData();
    },
    async loadMissions() {
      try {
        let response = await WalletCampaignRepository.getList();
        this.missions = response.data.data.data;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
  },
};
</script>

<style>
.set-width {
  width: 30% !important;
}
select.form-control { -webkit-appearance: menulist; }
</style>
